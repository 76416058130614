var scriptNowPlaying = {

  execute: function() {
    this.bindEvents();
  },

  bindEvents: function() {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  },

  onNowPlayingInfoChange : function(params){


    if(window.isIOSMobileDevice && 'NowPlaying' in window){
      // THis is only use in iOS

      if(params && params.detail){

        var opts = params.detail;
        var songTitle = opts[0];
        var artistName = opts[1];
        var albumName = opts[2];
        var albumId = opts[3];
        var coverArtBase64 = opts[4];
        var albumURL = opts[5];

        var nowPlayingInfo = {
         artwork: albumURL,
         albumTitle: albumName,
         artist: artistName,
         title: songTitle,

       };

       NowPlaying.set(nowPlayingInfo);
       NowPlaying._nowPlayingInfo = nowPlayingInfo;
       NowPlaying._elapsedTimeNeedsToBeUpdated = true;

     }
  
  }
},

onAudioStopped : function(){
  if(window.isIOSMobileDevice && 'NowPlaying' in window){
    NowPlaying._elapsedTimeNeedsToBeUpdated = true;

  }
},

onAudioPaused : function(){
 if(window.isIOSMobileDevice && 'NowPlaying' in window){
  NowPlaying._elapsedTimeNeedsToBeUpdated = true;

}
},

onAudioElapsedTimeChanged : function(params){
  if(window.isIOSMobileDevice && 'NowPlaying' in window){
    if(params && params.detail && NowPlaying._elapsedTimeNeedsToBeUpdated){
      var duration = params.detail.duration;
      var position = params.detail.position;
      if(duration && duration != -1){
       var newNowPlayingInfo = {};//NowPlaying._nowPlayingInfo;
       if(newNowPlayingInfo){
        newNowPlayingInfo.playbackDuration  = Math.round(duration/1000);
        newNowPlayingInfo.elapsedPlaybackTime  = Math.round(position/1000);
        NowPlaying.set(newNowPlayingInfo);
        NowPlaying._nowPlayingInfo =  newNowPlayingInfo;
        NowPlaying._elapsedTimeNeedsToBeUpdated = false;
      }
    }
  }
}
},


onDeviceReady: function() {
  //it looks like on ios when deviceready event is triggered, the NowPlaying plugin might not be yet atached to the object window
  document.addEventListener('updateMetaData', this.onNowPlayingInfoChange, false); 
  document.addEventListener('audioStopped', this.onAudioStopped, false);
  document.addEventListener('audioPaused', this.onAudioPaused, false);
  document.addEventListener('audioElapsedTimeChanged',this.onAudioElapsedTimeChanged, false);
},

};

scriptNowPlaying.execute();
